const NUMBERS = {
  ZERO: 0,
  ONE: 1,
  TWO: 2,
  THREE: 3,
  FOUR: 4,
  FIVE: 5,
  SIX: 6,
  SEVEN: 7,
  EIGHT: 8,
};

const INVALID_LINK = 'Невалидная ссылка';

const COLUMNS = {
  ONE: '1 строка (0%)',
  TWO: '2 строка (20%), округл',
};

const PROVIDER = 'provider';
const EXCEL = 'excel';

const SOURCE_TYPE = {
  TRIP_ITEM_ID: 'TripItemId',
  TICKET_NUMBER: 'TicketNumber',
};

export {
  NUMBERS,
  INVALID_LINK,
  COLUMNS,
  PROVIDER,
  EXCEL,
  SOURCE_TYPE,
};
