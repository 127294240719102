const TRAVEL_POLICY_CURRENCIES = {
  RUB: 0,
  USD: 1,
  EUR: 2,
  GBP: 3,
};

const TRAVEL_POLICY_CURRENCIES_LABELS = {
  [TRAVEL_POLICY_CURRENCIES.RUB]: 'рублей',
  [TRAVEL_POLICY_CURRENCIES.USD]: 'долларов',
  [TRAVEL_POLICY_CURRENCIES.EUR]: 'евро',
  [TRAVEL_POLICY_CURRENCIES.GBP]: 'фунтов',
};

export {
  TRAVEL_POLICY_CURRENCIES,
  TRAVEL_POLICY_CURRENCIES_LABELS,
};
