const USER_SETTINGS_FIELDS = {
  ONE_C_SALARY: 'settings1C',
  DISPLAY_PERSONAL_DATA: 'displayPersonalData',
  EMPLOUYEE_RESTRICTION: 'employeeRestriction',
  SALARY_1C: 'salary1C',
  HIDE_SECTION: 'hideSections',
  CART_EMPLOYEE_BIRTHDAY: 'cartEmployeeBirthday',
  // BIRTHDAY: 'birthday',
  DOCUMENTS: 'documents',
  FULL_NAME: 'fullName',
  NO_CHANGE_OR_RETURN_TRIP: 'noChangeOrReturnTrip',
  TRANSFER: 'transfer',
  VIP_HALL: 'vipHall',
  AEROEXPRESS: 'aeroExpress',
  CONFERENCE: 'conference',
  GROUP_CHECKING: 'groupCheckin',
  NOTIFICATION: 'notification',
  ADMIN_SETTINGS: 'adminSettingsSection',
  INVOICE_SEND_TYPE: 'invoiceSendType',
  VOUCHER_SEND_TYPE: 'voucherSendType',
  NO_PERSONAL_BONUS_CARDS: 'noPersonalBonusCards',
  USER_COMPANY_NOTIFICATION: 'userCompanyNotification',
  NO_EXPENSE_REPORT: 'noExpenseReport',
  REMOVE_FLIGHT_CERTIFICATE: 'removeFlightCertificate',
  ACCESS_FLIGHT_CERTIFICATES: 'accessFlightCertificates',
  DEPARTMENT_ONLY: 'departmentOnly',
  BUY_TRIP_ANY_STEP: 'buyTripAnyStep',
  HIDE_ADDITIONAL_ORDERS: 'hideAdditionalOrders',
  NO_MICE: 'noMice',
};

const LABEL_USER_SETTINGS_FIELDS = {
  [USER_SETTINGS_FIELDS.ONE_C_SALARY]: 'Дополнительные настройки',
  [USER_SETTINGS_FIELDS.HIDE_SECTION]: 'Скрыть раздел',
  [USER_SETTINGS_FIELDS.DISPLAY_PERSONAL_DATA]: 'Настройки отображения персональных данных',
  [USER_SETTINGS_FIELDS.EMPLOUYEE_RESTRICTION]: 'Ограничения сотрудника',
  [USER_SETTINGS_FIELDS.NOTIFICATION]: 'Уведомления',
  [USER_SETTINGS_FIELDS.ADMIN_SETTINGS]: 'Для администратора',
  [USER_SETTINGS_FIELDS.SALARY_1C]: 'Доступ к разделу Заявки в ЛК',
  [USER_SETTINGS_FIELDS.CART_EMPLOYEE_BIRTHDAY]: 'Показывать дату рождения сотрудников',
  [USER_SETTINGS_FIELDS.FULL_NAME]: 'Сотрудники с полным ФИО в поездке',
  [USER_SETTINGS_FIELDS.DOCUMENTS]: 'Показывать номер паспорта сотрудников',
  [USER_SETTINGS_FIELDS.NO_CHANGE_OR_RETURN_TRIP]: 'Запрет на аннуляцию и изменение поездок',
  [USER_SETTINGS_FIELDS.TRANSFER]: 'Трансфер',
  [USER_SETTINGS_FIELDS.VIP_HALL]: 'VIP-зал',
  [USER_SETTINGS_FIELDS.AEROEXPRESS]: 'Аэроэкспресс',
  [USER_SETTINGS_FIELDS.CONFERENCE]: 'Конференц-зал',
  [USER_SETTINGS_FIELDS.GROUP_CHECKING]: 'Групповой заезд',
  [USER_SETTINGS_FIELDS.INVOICE_SEND_TYPE]: 'Отправлять счета',
  [USER_SETTINGS_FIELDS.VOUCHER_SEND_TYPE]: 'Отправлять ваучеры',
  [USER_SETTINGS_FIELDS.NO_PERSONAL_BONUS_CARDS]: 'Запрет на добавление бонусной карты',
  [USER_SETTINGS_FIELDS.USER_COMPANY_NOTIFICATION]: 'Отправка уведомлений по компаниям:',
  [USER_SETTINGS_FIELDS.NO_EXPENSE_REPORT]: 'Авансовые отчеты',
  [USER_SETTINGS_FIELDS.REMOVE_FLIGHT_CERTIFICATE]: 'Запрет на покупку справок о перелете',
  [USER_SETTINGS_FIELDS.ACCESS_FLIGHT_CERTIFICATES]: 'Доступ к выписке справок о перелете',
  [USER_SETTINGS_FIELDS.DEPARTMENT_ONLY]: 'Ограничение видимости по отделам',
  [USER_SETTINGS_FIELDS.BUY_TRIP_ANY_STEP]: 'Выкупать поездку на любом шаге согласования',
  [USER_SETTINGS_FIELDS.HIDE_ADDITIONAL_ORDERS]: 'Запрет на покупку дополнительных услуг',
  [USER_SETTINGS_FIELDS.NO_MICE]: 'Мероприятие (MICE)',
};

export {
  USER_SETTINGS_FIELDS,
  LABEL_USER_SETTINGS_FIELDS,
};
