const TRANSFERTYPES = {
  AIRPORT: {
    TYPE: 'Airport',
    VALUE: 'Аэропорт',
  },
  RAILWAY: {
    TYPE: 'RailwayStation',
    VALUE: 'Ж.д. вокзал',
  },
  HOTEL: {
    TYPE: 'Hotel',
    VALUE: 'Гостиница',
  },
  ADDRESS: {
    TYPE: 'Address',
    VALUE: 'Адрес',
  },
};

export default TRANSFERTYPES;
